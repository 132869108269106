.App {
  margin-top: 10px;
}

img.logo {
  margin-bottom: 0 !important;
  height: 16px !important;
  width: auto !important;
}

.playlist-selection {
  margin-top: 10px;
}